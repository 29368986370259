<template>
    <div class="container">
        <div class="head">
            <img src="../assets/logo.png">
            <el-link href="/ad/" class="switch">
                <i class="el-icon-refresh" />
                切换高级版
            </el-link>
        </div>
        <div class="content">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container{
    .head{
        width:100%;
        height: 80px;
        background: rgba(17, 107, 191, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .switch{
            color: white;
            padding-right: 16px;
            font-size: 16px;
        }
        img{
            height: 60px;
            margin: 10px 50px;
            cursor: pointer;
        }
    }
    .content{
        background: rgba(240, 242, 245, 1);
        padding: 24px 50px;
    }
}
</style>